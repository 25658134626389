html,
body {
  overflow: hidden !important;
}

.myClass {
  max-width: 100% !important;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

p {
  word-wrap: break-word;
}

#root {
  min-height: 100vh;
}
